import RedMatches from "./RedMatches.png";
import 'tailwindcss/tailwind.css';
// import AbBlueMatch from './AbBlueMatch';
import BlueBM from "./BlueBM.png";



function AboutMatchPic() {

return (
    <div className="global">
        <div class="flex mb-4 grid grid-flow-col md:grid-flow">
            <img class ="ml-24" src ={RedMatches} alt="redMatchbox"/>
            <img class ="mt-8 pl-96" src ={BlueBM} alt="redMatchbox"/>

        </div>
    </div>
    )
    }

export default AboutMatchPic;

