
import React from "react";



function ProjectList () {


        const projects = [
            {
                src: "./OnlyHogsLogo.png",
                title: "OnlyHogs",
                link: "https://github.com/Maedevlin47/OnlyHogs"
            },

            {
                src: "./PlusOneLG.png",
                title: "PlusOne",
                link: "https://github.com/Maedevlin47/Plus-One"
            },
            {
                src: "./VrbiageLG.png",
                title: "Vrbiage",
                link: "https://github.com/Maedevlin47/vrbiage"
            },
            {
                src: "./HolidayHLG.png",
                title: "HolidayHunter",
                link: "https://github.com/Maedevlin47/Holiday-Hunter"
            }
            
        ];

            return (
                <div className="flex flex-wrap justify-center">
                {projects.map((project, index) => (
                    <div key={index} className="w-full sm:w-1/2 md:w-1/4 p-2">
                    <div className="shadow-lg rounded-lg">
                        <img
                        className="w-full h-48 object-cover object-center"
                        src={project.src}
                        alt={project.title}
                        />
                        <div className="px-4 py-2">
                        <h3 className="font-large text-3xl my-2">
                            {project.title}
                        </h3>
                        <div className="flex justify-center">
                            <a
                            href={project.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn rounded-full w-36 text-center bg-slate-400 text-2xl opacity-100 hover:opacity-60 animate-pulse"
                            >
                            Project Link
                            </a>
                        </div>
                        </div>
                    </div>
                    </div>
                ))}
                </div>
            );
            }
        
        export default ProjectList;





//         const onlyHLink = "https://github.com/Maedevlin47/OnlyHogs"
//         const vrbiage = "https://github.com/Maedevlin47/vrbiage"
//         const plusOne = "https://github.com/Maedevlin47/Plus-One"
//         const holidayHunter = "https://github.com/Maedevlin47/Holiday-Hunter"




//     return (
//         <div className="global pt-24 pb-28 flex justify-center">
//             <div className="container grid grid-cols-4 gap-4 sm:grid-cols-2 lg:grid-cols-4 pr-4 pl-4">
//                 <div className="margin-medium-top">
//                     <div className="card card-default card-hover border-rounded">
//                         <div className="card-header">
//                             <div className="grid grid-cols-1">
//                                 <div className="col-span-1">
//                                     <img src="./OnlyHogsLogo.png" className="" alt="OnlyHogsProject" />
//                                 </div>
//                                 <div className="col-span-1 flex items-center">
//                                     <h3 class="card-title margin-remove-bottom pt-4 pl-16 text-2xl">ONLY HOGS</h3>
//                                 </div>
//                             </div>
//                         </div>      
//                         <div class="card-body pl-10 pt-2">
//                             <p class="btn rounded-full w-40 h-8 inline-block bg-slate-400 text-lg animate-pulse">
//                                 <a className="justify-center text-2xl opacity-100 hover:opacity-30 pl-8 pt-6" href={onlyHLink} target="_blank" rel="noopener noreferrer">Project Link</a>                               
//                             </p>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="margin-large-top">
//                     <div className="card card-default card-hover border-rounded">
//                         <div className="card-header">
//                             <div className="grid grid-cols-2">
//                                 <div className="col-span-2">
//                                     <img src="./PlusOneLG.png" className="App-logo" alt="logo" />
//                                 </div>
//                                     <div className="col-span-1 flex items-center">
//                                         <h3 class="card-title margin-remove-bottom pl-28 pt-4 text-2xl">PLUS-ONE</h3>
//                                     </div>
//                                 </div>
//                                 <div class="card-body pl-20 pt-2">
//                                     <p class="btn rounded-full w-40 h-8 inline-block bg-slate-400 text-lg animate-pulse">
//                                         <a className="justify-center text-2xl opacity-100 hover:opacity-30 pl-8 pt-6" href={plusOne} target="_blank" rel="noopener noreferrer">Project Link</a>                               
//                                     </p>                               
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="margin-large-top">
//                         <div className="card card-default card-hover border-rounded">
//                             <div className="card-header">
//                                 <div className="grid grid-cols-3">
//                                     <div className="col-span-3">
//                                         <img src="./VrbiageLG.png" className="App-logo" alt="logo" />
//                                     </div>
// \                                    <div className="col-span-1 flex items-center">
//                                         <h3 class="card-title margin-remove-bottom pl-28 pt-4 text-2xl">VRBIAGE</h3>
//                                     </div>
//                                     <div class="card-body pl-20 pt-2">
//                                         <p class="btn rounded-full w-40 h-8 inline-block bg-slate-400 text-lg animate-pulse">
//                                             <a className="justify-center text-2xl opacity-100 hover:opacity-30 pl-8 pt-6" href={vrbiage} target="_blank" rel="noopener noreferrer">Project Link</a>                               
//                                         </p>                                
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="margin-large-top">
//                             <div className="card card-default card-hover border-rounded">
//                                 <div className="card-header">
//                                     <div className="grid grid-cols-4">
//                                         <div className="col-span-4">
//                                             <img src="./HolidayHLG.png" className="App-logo" alt="logo" />
//                                         </div>
//                                         <div className="col-span-1 flex items-center">
//                                             <h3 class="card-title margin-remove-bottom pl-20 pt-4 text-2xl">HOLIDAY HUNTER</h3>
//                                         </div>
//                                         <div class="card-body pl-20 pt-2">
//                                             <p class="btn rounded-full w-40 h-8 inline-block bg-slate-400 text-lg animate-pulse">
//                                                 <a className="justify-center text-2xl opacity-100 hover:opacity-30 pl-8 pt-6" href={holidayHunter} target="_blank" rel="noopener noreferrer">Project Link</a>                               
//                                             </p>                                      
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//         </div> 



//     )
// }

// export default ProjectList;