const Footer = () => {
    
    return (    
    <div className="global footer bg-slate-400 text-color">
        <footer className="sticky bottom-0 w-full text-center">
            <p className="inline-block text-2xl uppercase font-bold leading-snug">Mae Schreck | © 2023</p>
        </footer>
    </div>
    )
}
export default Footer;



// const Footer = () => {
//     return (
//       <div className="global text-color">
//         <main>...</main>
//         <footer className="bg-slate-400 w-full text-center">
//           <p className="inline-block text-2xl uppercase font-bold leading-snug">
//             Mae Schreck | © 2023
//           </p>
//         </footer>
//       </div>
//     );
//   };
  
//   export default Footer;

// origin fixed top-0 z-40 flex w-full flex-row justify-end sm:justify-between bg-slate-400 text-color