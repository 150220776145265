import React from "react";
// import VideoPlayer from "./SCVideo";
import SCVideo from "./SCVideo"
// import SCvideoRec from "./SCvideoRec.video.mp4";
import ProjectList from "./ProjectList"

function ProjectContainer() {

    const streetChariot = "https://github.com/Maedevlin47/StreetChariot"


        return (
            <div className="global main-background text-color">
                <div class="flex justify-center ">
                    <h1 class="text-6xl card-title mt-24 pt-4 mb-12 animate-bounce">PROJECTS PAGE</h1>
                </div>
                    <SCVideo className="flex justify-center"/>
                <div class="flex justify-center">
                    <h2 class="text-4xl card-title pt-4 pb-12">STREETCHARIOT</h2>
                </div>
                <div className="flex justify-center pb-12">
                    <h3 className="btn rounded-full w-44 md:w-60 h-24 pt-6 bg-slate-400 animate-pulse"> 
                        <a className="text-3xl sm:text-4xl pb-20 opacity-100 hover:opacity-30 flex justify-center items-center" href={streetChariot} target="_blank" rel="noopener noreferrer">
                            Project Link
                        </a>                      
                    </h3>
                </div>
            <br/>
\               <ProjectList/>
            <div className="flex justify-center text-3xl mt-16 mb-12">
                        NOTE: Projects are continually being updated.
            </div>
        </div>

        );
        }
    



export default ProjectContainer;