import MaeResume from "./MaeResume.png";
import 'tailwindcss/tailwind.css';



function ResumeShow() {
    return (
        <div className="flex justify-center pt-18 pb-32">
            <div className="border-8 border-slate-400 rounded-lg overflow-hidden sm:w-full md:w-2/3 lg:w-1/2 xl:w-1/3 2xl:w-2/4">
                <img className="w-full h-full object-cover sm:w-full md:w-full lg:w-full xl:w-full 1xl:w-full" src={MaeResume} alt="resume" />
            </div>
        </div>
    );
}
export default ResumeShow;












// function ResumeShow () {
    
//     return (
//         <div className="flex justify-center pt-18 pb-32 container-xl content-center">
//             <div className="border border-gray-300 rounded-lg overflow-hidden sm:w-full md:w-2/3 lg:w-1/2 xl:w-1/3 2xl:w-1/4">  
//                 <img className="flex justify-center" src={MaeResume} alt="candle" />
//             </div>
//         </div>

//       );
//     }


// return (
//     <div className="flex justify-center">
//         <div className="pt-18 pb-32 container-xl content-center border border-gray-300 rounded-lg overflow-hidden sm:w-full md:w-2/3 lg:w-1/2 xl:w-1/3 2xl:w-1/4">  
//             <img className="flex justify-center" src={MaeResume} alt="candle" />
//         </div>
//     </div>
//     );
//   }





//     return (
        
//         <div className ="flex justify-center pt-18 pb-32 container-xl content-center">
//             <img class="flex justify-center" src={MaeResume} alt="candle" />
//         </div>
//     )


// }

