import MedDogCar from './MedDogCar.png'
import 'tailwindcss/tailwind.css';

function HomeCartoonPic () {
return (
<div className="global main-background">
    {/* <div class="container mx-auto items-center pl-72 pt-32 pb-32"> */}
        <div class="flex justify-center mb-10 mt-10 items-center">
            <img class = "global h-2/4 max-w-screen-sm scale-120" src ={MedDogCar} alt="computer with candle"/>
        </div>
    {/* </div> */}
</div> 



)


}

export default HomeCartoonPic;