import React from "react";
import 'tailwindcss/tailwind.css';
import {Route, Routes} from 'react-router-dom';
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import 'tailwindcss/tailwind.css';
import ProjectContainer from './components/ProjectContainer';
import Resume from "./components/Resume";
import About from "./components/About";
import Home from "./components/Home";
import { useLocation } from 'react-router-dom';
// import HomeP from "./components/HomeP";
import './global.css';
import HomeCartoonPic from "./components/HomeCartoonPic";



    function App() {
    
    const location = useLocation();

    
    
    return (
        <body className="global main-background text-color">
            <div className="w-screen flex flex-col h-screen justify-between">
                <NavBar/>
                {location.pathname === '/' ? (
                <header className= "global items-center mt-24 pt-24 text-6xl card-title text-center uppercase leading-snug ">
                    <h1 className="animate-bounce"> 
                    Mae Schreck
                    <br/>
                    </h1>
                    <h2 className="text-center text-3xl">
                    Full Stack Software Engineer
                    <HomeCartoonPic/>
                    {/* <HomeP className=""/> */}
                    </h2>
                </header>
                        ): null}
                <div>
                    <Routes>
                        <Route exact path = "/projectcontainer" element={<ProjectContainer/>} />
                        <Route exact path = "/resume" element={<Resume/>} />
                        <Route exact path = "/about" element={<About/>} />
                    </Routes>
                </div>
                    <Home />
                    <Footer />
            </div>
        </body>
        );
    }

    export default App;



    // text-6xl card-title mt-12 ml-12 animate-bounce