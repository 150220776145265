import AstraMP from "./AstraMP.png";
import 'tailwindcss/tailwind.css';
// import Matches from './Matches.png';



function AstroMatch() {
    return (
        <div className="global">
            <div class="float-right">
                <img class = "scale-100 pr-48" src ={AstraMP} alt="BlueMatchbox"/>
            </div>
        </div>

    )

    }

export default AstroMatch;