import React from "react";
import AboutMatchPic from "./AboutMatchPic";
import AstroMatch from './AstroMatch';



function About() {
    return (
        <div className="global main-background text-color justify-center items-start ">
            <AstroMatch/>
            <div className="text-6xl card-title mt-28 ml-24 animate-bounce">
                ABOUT ME!
            </div>  
            <div className="bg rounded-xl container justify-center items-center w-full max-w-4xl mx-auto mt-4 p-12 text-3xl bg-red-200">
                <div className="text-left">
                    As a Software Engineer with experience and a strong skill set in business development and sales, I am very passionate about building and implementing new features that directly impact a users experience, and the continual improvement of those features.
                    <br />
                    <br />
                    My long term goal as a Software Engineer is to work cross functionally to bridge the communication gap that I have experienced between the Software Engineering/Product teams and Business Development/Sales teams within companies. I believe it is crucial that all team members creating a platform or application should have a strong relationship and feedback loop, with all team members working closely with the users of that creation.
                    <br />
                    <br />
                    PS. 
                    <br />
                    My family has been collecting the New Yorker covers and cartoons since the 80's, and I collect match boxes, hence the graphics!
                </div>
            </div>
            <AboutMatchPic/>
        </div>
        );
    }

    export default About;










    // function About() {
    //     return (
    //         <div className="main-background text-color justify-center items-start">
    //             <div className="flex text-5xl font-bold animate-bounce pl-24 pt-24">
    //                 ABOUT ME!
    //                 </div>
    //             <div className="flex justify-center items-center w-full max-w-4xl mx-auto mt-4 p-8 text-3xl bg-slate-300">
    //                 <div className="text-center">
    //                     As a Software Engineer with experience and a strong skill set in business development and sales, I am very passionate about building and implementing new features that directly impact a users experience, and the continual improvement of those features.
    //                     <br />
    //                     <br />
    //                     My long term goal as a Software Engineer is to work cross functionally to bridge the communication gap that I have experienced between the Software Engineering/Product teams and Business Development/Sales teams within companies. I believe it is crucial that all team members creating a platform or application should have a strong relationship and feedback loop, with all team members working closely with the users of that creation.
    //                 </div>
    //             </div>
    //             <MessageMePic />
    //             <div className="w-full flex justify-center flex-wrap">
    //                 <AboutMatchPic/>
    //                 <AboutPic/>
    //             </div>
    //             </div>
    
    //         // </div>
    //         );








    


    // <div className="flex pl-8 lg:w-10/12">
    // <br />
    // <div className="flex justify-center container ml-36 pb-6 mt-16 mb-12 pt-8 pl-8 pr-8 text-3xl bg-slate-300" style={{ textAlign: "center" }}>
    //     <div>
    //         As a Software Engineer with experience and a strong skill set in business development and sales, I am very passionate about building and implementing new features that directly impact a users experience, and the continual improvement of those features.
    //     <br />
    //     <br />
    //         My long term goal as a Software Engineer is to work cross functionally to bridge the communication gap that I have experienced between the Software Engineering/Product teams and Business Development/Sales teams within companies. I believe it is crucial that all team members creating a platform or application should have a strong relationship and feedback loop, with all team members working closely with the users of that creation.
    //     </div>
    // </div>
    // </div>














// function About() {
//     return (
//       <div className="main-background text-color" style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh" }}>
//         <div className="flex flex-col items-center lg:w-10/12">
//           <AboutMatchPic />
//           <div className="mt-20 text-4xl pl-8 font-bold pt-.5 animate-bounce">ABOUT ME!</div>
//           <div className="flex pl-8">
//             <AboutPic className="" />
//             <div className="container ml-24 pb-6 mt-4 mb-12 pt-8 pl-8 pr-8 text-3xl bg-slate-300" style={{ textAlign: "center" }}>
//               <div>
//                 As a Software Engineer with experience and a strong skill set in business development and sales, I am very passionate about building and implementing new features that directly impact a users experience, and the continual improvement of those features.
//                 <br />
//                 <br />
//                 My long term goal as a Software Engineer is to work cross functionally to bridge the communication gap that I have experienced between the Software Engineering/Product teams and Business Development/Sales teams within companies. I believe it is crucial that all team members creating a platform or application should have a strong relationship and feedback loop, with all team members working closely with the users of that creation.
//               </div>
//             </div>
//             <MessageMePic />
//           </div>
//         </div>
//       </div>
//     );
//   }
  
//   export default About;
  
















// function About() {
//         return (
//         <div className="main-background text-color">
//             <AboutMatchPic />
//             <div className="mt-20 text-4xl pl-8 font-bold pt-.5 animate-bounce">ABOUT ME!</div>
//             <div className="flex pl-8 lg:w-10/12">
//             <br />
//             <div className="flex justify-center container ml-24 pb-6 mt-4 mb-12 pt-8 pl-8 pr-8 text-3xl bg-slate-300" style={{ textAlign: "center" }}>
//                 <div>
//                 As a Software Engineer with experience and a strong skill set in business development and sales, I am very passionate about building and implementing new features that directly impact a users experience, and the continual improvement of those features.
//                 <br />
//                 <br />
//                 My long term goal as a Software Engineer is to work cross functionally to bridge the communication gap that I have experienced between the Software Engineering/Product teams and Business Development/Sales teams within companies. I believe it is crucial that all team members creating a platform or application should have a strong relationship and feedback loop, with all team members working closely with the users of that creation.
//                 </div>
//             </div>
//                     <AboutPic/>
//                     <MessageMePic/>
//             </div>
//         </div>
//         );
//     }
    
//     export default About;
















// function About() {

//         return (
//             <div className= "main-background text-color"> 
//                 <AboutMatchPic/>
//                 <div className="mt-20 text-4xl pl-8font-bold pt-.5 animate-bounce">ABOUT ME!</div>
//                 <div class="flex pl-8 lg:w-10/12">
//                 <br/>
//                     <div class="flex justify-center container ml-24 pb-6 mt-4 mb-12 pt-8 pl-8 pr-8 text-3xl bg-slate-300">
//                         <div ClassName="flex justify-center"> 
//                         As a Software Engineer with experience and a strong skill set in business development and sales, I am very passionate about building and implementing new features that directly impact a users experience, and the continual improvement of those features.
//                         <br/>
//                         <br/>
//                         My long term goal as a Software Engineer is to work cross functionally to bridge the communication gap that I have experienced between the Software Engineering/Product teams and Business Development/Sales teams within companies. I believe it is crucial that all team members creating a platform or application should have a strong relationship and feedback loop, with all team members working closely with the users of that creation.
//                         </div>
//                     </div>
//                     <AboutPic/>
//                     <MessageMePic/>
//                 </div>
//                 </div>
//         );
//     }


// export default About;




