import 'tailwindcss/tailwind.css';
import { NavLink } from "react-router-dom";
import { useState } from 'react';


    function NavBar() {
        const [isOpen, setIsOpen] = useState(false);
    
        const toggleMenu = () => {
        setIsOpen(!isOpen);
        };
    
        return (
        <nav className="global origin fixed top-0 z-40 flex w-full flex-row justify-end sm:justify-between bg-slate-400 text-color">
                <div className="container w-full mx-auto flex flex-wrap mt-4 mb-4 items-center justify-between">
                <div className="w-full relative flex justify-between lg:w-auto px-4 lg:static"></div>
                <div className="lg:flex flex-grow">
                <NavLink className="text-xl font-bold leading-none flex flex-col lg:flex-row list-none ml-auto" to="/">
                </NavLink>
                <button
                    type="button"
                    className="block lg:hidden focus:outline-none"
                    onClick={toggleMenu}>
                        <svg
                            className="w-6 h-6 fill-current"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg">
                            <title>Menu</title>
                            <path
                            d="M0 3h20v2H0V3zm0 7h20v2H0v-2zm0 7h20v2H0v-2z"
                            />
                        </svg>
                </button>
                <ul
                className={`${
                    isOpen ? 'block' : 'hidden'
                } lg:flex lg:flex-row list-none ml-auto`}
                >
                <li className="nav-item">
                    <NavLink
                        className="btn rounded-xl bg-red-200 mx-3 px-2 py-2 flex items-center text-xl uppercase font-bold leading-snug hover:opacity-50"
                        role="button"
                        exact
                        to="/"
                        onClick={toggleMenu}>
                        Home
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink
                        className="btn rounded-xl bg-red-200 mx-3 px-2 py-2 flex items-center text-xl uppercase font-bold leading-snug hover:opacity-50"
                        role="button"
                        exact
                        to="/projectcontainer"
                        onClick={toggleMenu}>
                        Projects
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink
                        className="btn rounded-xl bg-red-200 mx-3 px-2 py-2 flex items-center text-xl uppercase font-bold leading-snug hover:opacity-50"
                        role="button"
                        exact
                        to="/about"
                        onClick={toggleMenu}>
                        About
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink
                        className="btn rounded-xl bg-red-200 mx-3 px-2 py-2 flex items-center text-xl uppercase font-bold leading-snug hover:opacity-50"
                        role="button"
                        exact
                        to="/resume"
                        onClick={toggleMenu}>
                        Resume
                    </NavLink>
                </li>
                </ul>
            </div>
            </div>
        </nav>
        );
    }


    export default NavBar;
















// function NavBar (){

//         return (
//             <nav className="relative flex flex-wrap items-right justify-between px-2 py-3 bg-slate-400 text-color">
//                 <div className="container ml-96 justify-between">
//                 <div className="lg:flex">
//                     <ul className="flex lg:flex-row list-none ml-auto">
//                         <li className="nav-item">
//                             <NavLink className="btn rounded-xl bg-slate-200 mx-3 px-2 py-2 flex items-center text-2xl uppercase font-bold leading-snug hover:opacity-50" role = "button" exact to= "/">Home</NavLink>
//                         </li>
//                         <li className="nav-item">
//                             <NavLink className="btn rounded-xl bg-slate-200 mx-3 px-2 py-2 flex items-center text-2xl uppercase font-bold leading-snug hover:opacity-50" role = "button" exact to= "/projectcontainer">Projects</NavLink>
//                         </li>
//                         <li className="nav-item">
//                             <NavLink className="btn rounded-xl bg-slate-200 mx-3 px-2 py-2 flex items-center text-2xl uppercase font-bold leading-snug hover:opacity-50" role = "button" exact to= "/about">About</NavLink>
//                         </li>
//                         <li className="nav-item">
//                             <NavLink className="btn rounded-xl bg-slate-200 mx-3 px-2 py-2 flex items-center text-2xl uppercase font-bold leading-snug hover:opacity-50" role = "button" exact to= "/resume">Resume</NavLink>
//                         </li>
//                     </ul>
//                 </div>
//             </div>
//         </nav>
//         );
//     }


// export default NavBar;

// href="#pablo"

/* <li className="nav-item">
                            <NavLink className="px-3 py-2 flex items-center text-2xl uppercase font-bold leading-snug hover:opacity-50" role = "button" exact to= "/messageme">Message Me</NavLink>
                        </li> */